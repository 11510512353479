const urls = {
  systemStatus:
    process.env.REACT_APP_SYSTEM_STATUS_URL ??
    process.env.react_app_system_status_url ??
    "https://d146pottjsqws2.cloudfront.net/system-status.json",
};

const api = {
  baseURL: "https://s7dhgb38xk.execute-api.eu-central-1.amazonaws.com/prod/",
  endpoints: {
    preRegistration: "/pre-registration",
    contact: "/contact",
  },
};

const config = {
  api,
  urls,
};

export default config;
