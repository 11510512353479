import React, { useState } from "react";
import { Box, Grid, Stack, Typography, Container, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { useTranslation, Trans } from "react-i18next";
import Notify from "../common/Notify";
import parse from "html-react-parser";

type VideoButtonProps = {
  videoUrl: string;
};

const VideoButton: React.FC<VideoButtonProps> = ({ videoUrl }) => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.Summary.Video" });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={openModal}
        sx={{
          color: "white",
          border: "2px solid #FFC040",
          px: 6,
          fontSize: 16,
          fontWeight: 500,
        }}
      >
        {t("title")}
      </Button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            height: "auto",
            padding: 0,
            justifyContent: "center",
            border: "none",
            background: "transparent",
            overflow: "hidden",
          },
        }}
      >
        <ReactPlayer url={videoUrl} width="100%" controls />
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: -5,
            top: -5,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal>
    </>
  );
};

type SummaryProps = {
  productId: string;
};

const Summary: React.FC<SummaryProps> = ({ productId }) => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.Summary" });
  const title = (
    <Trans t={t} components={{ span: <span /> }}>
      {t("title")}
    </Trans>
  );

  return (
    <Box
      pt={{ xs: 0, lg: 2 }}
      sx={{
        color: "common.white",
        background: {
          xs: "linear-gradient(180deg, rgba(0,0,0,1) 60%, rgba(255,255,255,0) 100%)",
          md: `url("${t("img.src")}") no-repeat`,
        },
        backgroundSize: {
          xs: "cover !important",
          md: "cover !important",
          lg: "cover !important",
        },
        backgroundPosition: {
          xs: "center !important",
          md: "right top !important",
        },
      }}
    >
      <Grid
        container
        alignItems="flex-end"
        display={{ md: "none" }}
        sx={{
          background: `url("${t("img.src")}") no-repeat`,
          backgroundSize: "cover !important",
          backgroundPosition: "right top !important",
          py: { xs: 0, md: 4, lg: 4 },
          minHeight: { xs: "300px", md: "350px", lg: "350px" },
          pb: 0,
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%)",
            width: "100%",
          }}
        >
          <Container>
            <Grid item xs={12} sm={6} pt="150px">
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: "1.8rem",
                  pr: { xs: 2, md: 4, lg: 4 },
                  pl: { xs: 2, md: 4 },
                  span: {
                    color: "primary.main",
                  },
                }}
                data-aos="fade-up"
              >
                {title}
              </Typography>
            </Grid>
          </Container>
        </Box>
      </Grid>
      <Container>
        <Grid container py={{ xs: 2, lg: 20 }}>
          <Grid
            container
            item
            xs={12}
            lg={7}
            minHeight={{ xs: "250px", md: "300px" }}
            sx={{ height: "100%", pr: { lg: 8 }, pt: { lg: 4 } }}
          >
            <Grid item xs={12} display={{ xs: "none", md: "block" }}>
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: "2.5rem", lg: "2.8rem" },
                  span: {
                    color: "primary.main",
                  },
                }}
                data-aos="fade-up"
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={{ xs: 0, md: 4, lg: 4 }} pr={{ xs: 2, md: 0, lg: 2 }} pl={{ xs: 2, md: 0, lg: 0 }}>
              <Typography data-aos="fade-up" data-aos-delay="200">
                {parse(t("description"))}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={{ xs: 0, md: 4, lg: 4 }}>
              <Stack spacing={2} direction="row">
                <Notify productId={productId} sx={{ px: { xs: 3, md: 4, lg: 8 } }} />
                <VideoButton videoUrl="https://www.youtube.com/watch?v=6yZ9SsFmIxk" />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Summary;
