import { useState } from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import MobileMenu from "./MobileMenu";
import Logo from "./Logo";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "../LanguageSwitch";

export type MenuItems = {
  id: number;
  title: string;
  url: string;
  btn?: boolean;
  subMenu?: {
    id: number;
    title: string;
    url: string;
    imgSrc: string;
  }[];
}[];

const Header = () => {
  const { t } = useTranslation("", { keyPrefix: "Header" });
  const menuItems: MenuItems = t("menu", { returnObjects: true });

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar component="nav" elevation={25}>
      <Container>
        <Toolbar disableGutters>
          <MobileMenu
            menuItems={menuItems}
            onDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />

          <Logo />

          <Menu menuItems={menuItems} />
          <Box sx={{ width: "48px", textAlign: "center", fontSize: "1.2rem" }}>
            <LanguageSwitch
              sx={{ display: { md: "none" } }}
              shortcode={true}
              underline="none"
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
