import axios from "axios";
import { Container, Grid, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import config from "../config";

type SystemStatus = {
  serviceID: number;
  serviceName: string;
  available: boolean;
}[];

const GreenCircle = styled("div")({
  width: "15px",
  height: "15px",
  backgroundColor: "#28c940",
  borderRadius: "50%",
  marginRight: "10px",
});

const RedCircle = styled("div")({
  width: "15px",
  height: "15px",
  backgroundColor: "red",
  borderRadius: "50%",
  marginRight: "10px",
});

export default function DenseTable() {
  const [systemStatus, setSystemStatus] = useState<SystemStatus>([]);

  useEffect(() => {
    axios.get(config.urls.systemStatus).then((response) => {
      setSystemStatus(response.data);
    });
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} pt={{ xs: 3, lg: 5 }}>
          <Typography
            variant="h1"
            component="h2"
            sx={{
              fontSize: { xs: "2.2rem", lg: "3rem" },
              span: {
                color: "primary.main",
              },
            }}
          >
            System Status
          </Typography>
        </Grid>

        <Grid container py={{ xs: 3, lg: 5 }} justifyContent="center">
          {systemStatus.map((status, i) => (
            <Grid
              container
              item
              xs={12}
              sm={6}
              lg={3}
              borderTop={{
                xs: i + 1 <= 1 ? 1 : 0,
                sm: i + 1 <= 2 ? 1 : 0,
                lg: i + 1 <= 4 ? 1 : 0,
              }}
              borderBottom={1}
              borderRight={{
                sm: (i + 1) % 2 === 0 ? 0 : 1,
                lg: (i + 1) % 4 === 0 ? 0 : 1,
              }}
              padding={1}
              direction="row"
              alignItems="center"
              borderColor={(theme) => `${theme.palette.grey[300]} !important`}
            >
              <Grid item>{status.available ? <GreenCircle /> : <RedCircle />}</Grid>
              <Grid item>{status.serviceName}</Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
