import { createTheme } from "@mui/material/styles";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/700.css";

let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FFC040",
      light: "#FFCC66",
      dark: "#ffab00",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#2F7BFB",
      dark: "#04A1F4",
      light: "#E4F5FF",
      contrastText: "#FFFFFF",
    },
    tertiary: {
      main: "#56CB75",
      light: "#E9EFFD",
      light1: "#EBFFFF",
      dark: "#4F52FF",
    },
    info: {
      main: "#434343",
      light: "#dddddd",
      dark: "#000000",
      contrastText: "#FFFFFF",
    },
    grey: {
      100: "#F4F4F4",
      600: "#797979",
      800: "#434343",
    },
    dark: {
      main: "#0F0F0F",
    },
    text: {
      primary: "#0F0F0F",
      secondary: "#434343",
      tertiary: "#797979",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 600,
    },
  },
  transitions: {
    easing: {
      easeOut: "cubic-bezier(0.75, 0, 0.175, 1)",
    },
  },
});

theme.shadows.push(
  "0px 4px 15px 0px #0000000D",
  "0px 0px 15px 2px rgba(24, 37, 45, 0.08)",
  "0px 0px 15px 0px #EBEBEB",
  "0px 0px 15px 5px rgba(175, 92, 214, 0.2)",
  "0px 0px 40px 10px rgba(26, 15, 145, 0.1)"
);

theme = createTheme(theme, {
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
          padding: "10px 0",
          position: "sticky",
          ".MuiToolbar-root": {
            position: "static",
          },
          ".MuiLink-root": {
            color: theme.palette.text.primary,
            "&:hover, &.Mui-focusVisible": {
              color: theme.palette.secondary.main,
            },
            "&.active": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
  },
});

export default theme;
