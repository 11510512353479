import { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  Modal,
  FormControlLabel,
  Snackbar,
  Alert,
  ButtonProps,
} from "@mui/material";
import http from "../../services/httpService";
import config from "../../config";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { HashNavLink } from "../custom/HashNav";
import ReactGA from "react-ga4";

type NotifyProps = ButtonProps & {
  productId: string;
};

const Xbox = ({ productId = "", ...rest }: NotifyProps) => {
  const { t } = useTranslation("", { keyPrefix: "Notify" });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [agree, setAgree] = useState(false);
  const isEmailValid = validator.isEmail(email);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const handleSubscription = async () => {
    ReactGA.event({
      category: "Button",
      action: "Subscribe",
      label: "Label",
    });

    try {
      if (email && productId && isEmailValid) {
        await http.post(config.api.endpoints.preRegistration, {
          email,
          product_id: productId,
          name,
        });
        setShowSuccessMsg(true);
      }
    } catch (error) {
      setShowErrorMsg(true);
    }
  };

  const openKickstarter = () => {
    window.open("https://shop.newmove.energy/", "_blank", "noreferrer");
  };

  return (
    <>
      <Button onClick={() => openKickstarter()} variant="contained" {...rest}>
        {t("btn.notify")}
      </Button>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <>
          <Grid
            container
            item
            xs="auto"
            component="form"
            direction="column"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              p: 4,
              bgcolor: "background.default",
              borderRadius: 1,
              minWidth: "350px",
            }}
          >
            <Grid item>
              <TextField
                label={t("email.label")}
                type="email"
                value={email}
                error={email === "" ? false : !isEmailValid}
                onChange={(e) => setEmail(e.currentTarget.value)}
                placeholder={t("email.placeholder")}
                inputProps={{ maxLength: 125 }}
                color="secondary"
                fullWidth
                focused
              />
            </Grid>
            <Grid item mt={2}>
              <TextField
                label={t("name.label")}
                type="text"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                placeholder={t("name.placeholder")}
                inputProps={{ maxLength: 256 }}
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <FormControlLabel
                label={t("checkbox")}
                control={
                  <Checkbox color="secondary" checked={agree} onChange={(e) => setAgree(e.target.checked)}></Checkbox>
                }
              />
            </Grid>
            <Grid item xs={12} mt={2} alignSelf="end">
              <HashNavLink color="secondary" to={t("privacy.url")}>
                {t("privacy.text")}
              </HashNavLink>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Button
                variant="contained"
                onClick={handleSubscription}
                disabled={!(agree && isEmailValid)}
                color="secondary"
                size="large"
              >
                {t("btn.submit")}
              </Button>
            </Grid>
          </Grid>

          <Snackbar
            open={showSuccessMsg}
            autoHideDuration={3000}
            onClose={() => {
              setShowSuccessMsg(false);
              setIsModalOpen(false);
            }}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              {t("alert.success")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorMsg}
            autoHideDuration={3000}
            onClose={() => {
              setShowErrorMsg(false);
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {t("alert.error")}
            </Alert>
          </Snackbar>
        </>
      </Modal>
    </>
  );
};

export default Xbox;
