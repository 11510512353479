import { useTranslation } from "react-i18next";
import theme from "../utils/theme";
import Xbox from "../components/common/Xbox";
import Summary from "../components/newmove/Summary";
import MetaTags from "../components/common/MetaTags";
import MadeInGermany from "../components/newmove/MadeInGermany";
import OurHardware from "../components/newmove/OurHardware";
import PowerPeak from "../components/newmove/PowerPeak";
import ShopNow from "../components/newmove/ShopNow";
// import Contact from "../components/newmove/Contact";
// import StandalonePackage from "../components/common/StandalonePackage";
// import OrderNow from "../components/newmove/OrderNow";
// import Ticker from "../components/common/Ticker";

const Newmove = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove" });
  const productId = t("productId");
  const features: {
    id: string;
    title_feature: string;
    title: string;
    description: string;
    img: { src: string; alt: string };
    headerImg?: { src: string; alt: string };
    customTitle?: boolean;
  }[] = t("Features", { returnObjects: true });

  return (
    <>
      {/* <Ticker /> */}
      <MetaTags t={t} />
      <Summary productId={productId} />
      <div id="section1">
        <Xbox
          type="split"
          title={t("Setup.title")}
          description={t("Setup.description")}
          fillImg
          imgAlt={t("Setup.img.alt")}
          imgSrc={t("Setup.img.src")}
          sx={{
            color: "#474747",
            ".MuiGrid-root": {
              pb: { xs: 2, md: 0 },
            },
            ".xbox-img img": {
              pl: { xs: 2, md: 6 },
              pr: { xs: 2, md: 0 },
            },
            ".MuiGrid-container": {
              mt: { md: 0, lg: 0 },
              mr: { md: 6, lg: 0 },
            },
            ".MuiTypography-root": {
              pr: { xs: 2, md: 2, lg: 8 },
              pl: { xs: 2, md: 0 },
              mt: { xs: 0, md: 0, lg: 4 },
            },
            ".MuiTypography-body1": {
              textAlign: "justify",
              hyphens: "auto",
              pr: { md: 4, lg: 10 },
              mt: { xs: 1, md: 0, lg: 0 },
            },
          }}
        />
        <Xbox
          type="split"
          title={t("SolarGraph.title")}
          description={t("SolarGraph.description")}
          reverse
          fillImg
          imgAlt={t("SolarGraph.img.alt")}
          imgSrc={t("SolarGraph.img.src")}
          sx={{
            color: "#474747",
            pt: { xs: 0, md: 10, lg: 0 },
            pb: { xs: 0, md: 6, lg: 4 },
            ".MuiGrid-root": {
              pb: { xs: "10px", md: "40px" },
              alignContent: "center",
            },

            ".xbox-img img": {
              pt: { xs: 2, md: 2, lg: 10 },
              pl: { xs: 2, md: 0 },
              pr: { xs: 2, md: 0 },
            },
            ".MuiGrid-container": {
              mt: { md: 0, lg: 0 },
              mr: { md: 6, lg: 0 },
            },
            ".MuiTypography-root": {
              pr: { xs: 2, md: 2 },
              pl: { xs: 2, md: 0 },
              mt: { xs: 0, md: 0 },
            },
            ".MuiTypography-body1": {
              textAlign: "justify",
              hyphens: "auto",
              pr: { md: 5, lg: 6 },
              mt: { md: 2, lg: 0 },
            },
          }}
        />
        <PowerPeak />
      </div>
      <div id="section2">
        {features.map((feature, i) => (
          <Xbox
            reverse={i % 2 === 0 ? true : false}
            type="split"
            isFeature={true}
            key={feature.id}
            title={feature.title}
            description={feature.description}
            imgAlt={feature.img.alt}
            imgSrc={feature.img.src}
            headerImgAlt={feature.headerImg?.alt}
            headerImgSrc={feature.headerImg?.src}
            className={`feature-${feature.id} ${feature.customTitle ? "custom-title" : ""}`}
            sx={{
              py: 0,
              color: "#474747",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              letterSpacing: "0.25px",
              ".MuiGrid-container": { alignItems: "center" },
              ".MuiGrid-root": { pb: { xs: 2 }, display: "flex", alignItems: "center" },
              ".xbox-left": { alignContent: "center" },
              ".xbox-img": { padding: { xs: 4, lg: 6 } },
              ".feature-2": {
                pb: { xs: 0, md: 2, lg: 8 },
              },
              ".custom-title": {
                color: "grey",
              },
            }}
          />
        ))}
      </div>
      <MadeInGermany />
      <Xbox
        type="split"
        reverse
        isYoutube={true}
        title={t("Xbox.title")}
        description={t("Xbox.description")}
        youtube="https://www.youtube.com/watch?v=Rggefu2FTsw"
        leftBgcolor={theme.palette.grey[800]}
        rightBgcolor={theme.palette.grey[800]}
        fillImg
        sx={{
          color: "common.white",
          py: { xs: 6, md: 0, lg: 8 },
          ".MuiGrid-container": {
            paddingBottom: { xs: 0 },
            paddingTop: { md: 0, lg: 0 },
            alignItems: "center",
          },
          ".MuiTypography-root": {
            pr: { xs: 2, md: 2, lg: 14 },
            pl: { xs: 2, md: 2, lg: 0 },
            mt: { xs: 2, md: 2, lg: 2 },
          },
          ".MuiTypography-body1": {
            hyphens: "auto",
            textAlign: { xs: "center", md: "justify", lg: "justify" },
            pt: { xs: 1, md: 2, lg: 2 },
          },
        }}
      />
      <div id="section4">
        <OurHardware />
      </div>
      <ShopNow />
      {/* <StandalonePackage productId={productId} />
      <OrderNow />
      <Contact /> */}
    </>
  );
};

export default Newmove;
