import React, { useState, ChangeEvent, FC } from "react";
import { Alert, Button, Container, Grid, Snackbar, Typography, Box, Link, SelectChangeEvent } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import http from "../../services/httpService";
import config from "../../config";
import ReactGA from "react-ga4";

const ContactForm = () => {
  const { t } = useTranslation("", { keyPrefix: "Contact" });
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formError, setFormError] = useState({
    name: "",
    email: "",
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    switch (name) {
      case "name": {
        const nameRegex = /^[A-Za-z\s]+$/;
        if (value.match(nameRegex) || value === "") {
          setFormData((prev) => ({ ...prev, [name]: value }));
          setFormError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setFormError((prev) => ({ ...prev, [name]: t("inputs.nameRejex") }));
        }
        break;
      }
      case "email": {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        setFormData((prev) => ({ ...prev, [name]: value }));
        if (value.match(emailRegex) || value === "") {
          setFormError((prev) => ({ ...prev, [name]: "" }));
        } else {
          setFormError((prev) => ({ ...prev, [name]: t("inputs.emailRejex") }));
        }
        break;
      }
      default: {
        setFormData((prev) => ({ ...prev, [name]: value }));
        break;
      }
    }
  };

  const handleSubmit = async () => {
    ReactGA.event({
      category: "Button",
      action: "Submit",
      label: "Contact",
    });

    try {
      setIsLoading(true);
      if (formData.name && formData.email && formData.message && !formError.name && !formError.email) {
        await http.post(config.api.endpoints.contact, formData);
        setFormData({ name: "", email: "", message: "" });
        setShowSuccessMsg(true);
      } else {
        setShowErrorMsg(true);
      }
    } catch (error) {
      setShowErrorMsg(true);
    } finally {
      setIsLoading(false);
    }
  };

  const InfoBox: FC<{ label: string; value: string; link?: string }> = ({ label, value, link }) => (
    <Box>
      <Typography sx={{ display: { xs: "inline-flex" } }} variant="subtitle2">
        {label}
      </Typography>
      <Typography
        component={link ? Link : "span"}
        href={link || undefined}
        sx={{
          display: { xs: "inline-flex" },
          pl: 1,
          color: "#2F7BFB",
          textDecoration: "none",
          "&:hover": { textDecoration: "underline" },
        }}
        variant="subtitle2"
      >
        {value}
      </Typography>
    </Box>
  );

  return (
    <Container id="contact-form">
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          data-aos="fade-up"
          sx={{
            textAlign: { xs: "center", md: "start" },
            py: { xs: 4, md: 6 },
          }}
        >
          <Typography fontWeight="600" color="black" variant="h4" sx={{ pb: { xs: 4, md: 7 } }}>
            {t("title")}
          </Typography>
          <img
            src={t("img.src")}
            alt={t("img.alt")}
            data-aos="fade-up"
            style={{
              width: 240,
              height: 240,
              borderRadius: "50%",
              marginBottom: "1rem",
            }}
          />
          <Typography variant="body2" sx={{ pb: { xs: 2, md: 10 }, pr: { xs: 0, md: 10 } }}>
            <Trans t={t} components={{ br: <br /> }}>
              {t("subtitle")}
            </Trans>
          </Typography>
          <InfoBox label="Email:" value={t("mail")} link={`mailto:${t("email")}`} />
          <InfoBox label="Phone:" value={t("tel")} link={`tel:${t("phone")}`} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            px: { xs: 2, md: 2 },
            py: { xs: 0, md: 2 },
            pr: { xs: 2, md: 8 },
            mb: { xs: 2, md: 0 },
            bgcolor: "#2F7BFB",
            color: "#fff",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="500"
            sx={{ pl: { xs: 2, md: 2 }, pt: { xs: 3, md: 5 }, pb: { xs: 2, md: 3 } }}
          >
            {t("form.title")}
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "1rem",
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                {t("inputs.name")}
              </Typography>
              <input
                id="name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                required
                placeholder={t("inputs.name")}
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  border: "1px solid #AF5CD6",
                  backgroundColor: "#fff",
                  color: "#7A6682",
                  width: "100%",
                }}
              />
              {formError.name !== "" && (
                <Typography variant="subtitle1" sx={{ color: " #f39c12", mt: 1, pl: 1 }}>
                  {formError.name}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                {t("inputs.email")}
              </Typography>
              <input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                placeholder={t("inputs.email")}
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  border: "1px solid #AF5CD6",
                  backgroundColor: "#fff",
                  color: "#7A6682",
                  width: "100%",
                }}
              />
              {formError.email !== "" && (
                <Typography variant="subtitle1" sx={{ color: " #f39c12", mt: 1 }}>
                  {formError.email}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography variant="subtitle1" sx={{ color: "#fff", mb: 1 }}>
                {t("inputs.message")}
              </Typography>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                maxLength={250}
                required
                placeholder={t("inputs.messagePlaceholder")}
                rows={5}
                style={{
                  padding: "20px",
                  borderRadius: "5px",
                  border: "1px solid #AF5CD6",
                  backgroundColor: "#fff",
                  fontFamily: "Inter",
                  color: "#7A6682",
                  width: "100%",
                  resize: "none",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                mt: { xs: 0, md: 3 },
                mb: { xs: 0, md: 3 },
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  isLoading ||
                  formData.name === "" ||
                  formError.name !== "" ||
                  formData.email === "" ||
                  formError.email !== "" ||
                  formData.message === ""
                }
                sx={{
                  bgcolor: "#fff",
                  color: "#a64dff",
                  "&: hover": { color: "#fff" },
                }}
              >
                {t("inputs.button")}
              </Button>
            </Box>
          </Box>
          <Snackbar
            open={showSuccessMsg}
            autoHideDuration={5000}
            onClose={() => setShowSuccessMsg(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setShowSuccessMsg(false)} severity="success">
              {t("alert.success")}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorMsg}
            autoHideDuration={5000}
            onClose={() => setShowErrorMsg(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setShowErrorMsg(false)} severity="error">
              {t("alert.error")}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
