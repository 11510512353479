import { Container, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import MetaTags from "../components/common/MetaTags";

const LegalDisclosure = () => {
  const { t } = useTranslation("", { keyPrefix: "LegalDisclosure" });

  return (
    <>
      <MetaTags t={t} />
      <Container sx={{ py: 10, span: { fontSize: "2rem" } }}>
        <Typography variant="body1">
          <Trans t={t} components={{ span: <span />, br: <br /> }}>
            {t("content")}
          </Trans>
        </Typography>
      </Container>
    </>
  );
};

export default LegalDisclosure;
