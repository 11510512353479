import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavHashLink } from "react-router-hash-link";
import parse from "html-react-parser";
import MetaTags from "../components/common/MetaTags";

type FAQs = {
  title: string;
  items: {
    question: string;
    answer: string;
  }[];
}[];

const FAQ = () => {
  const { t } = useTranslation("", { keyPrefix: "FAQ" });
  const faqs: FAQs = t("content", { returnObjects: true });

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    marginBottom: 5,
    padding: 8,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  return (
    <>
      <MetaTags t={t} />
      <Container>
        <Grid container>
          <Grid item xs={12} pt={{ xs: 3, lg: 5 }}>
            <Typography
              variant="h1"
              component="h2"
              sx={{
                fontSize: { xs: "2.2rem", lg: "3rem" },
                span: {
                  color: "primary.main",
                },
              }}>
              {"FAQs"}
            </Typography>
          </Grid>

          {faqs.map((category) => (
            <>
              <Grid item xs={12} pt={{ xs: 3, lg: 5 }} pb={{ xs: 2, lg: 3 }}>
                <Typography
                  variant="h2"
                  component="h3"
                  sx={{
                    fontSize: { xs: "1.4rem", lg: "1.8rem" },
                    span: {
                      color: "primary.main",
                    },
                    mt: 2,
                  }}>
                  {category.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {category.items.map((faq, i) => (
                  <Accordion key={i}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography fontWeight={450}>{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{parse(faq.answer)}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </>
          ))}
          <Grid container item xs={12} py={{ xs: 3, lg: 5 }}>
            <Typography>
              Your question not mentioned?{" "}
              <NavHashLink to="/#contact-us">Contact Us</NavHashLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default FAQ;
