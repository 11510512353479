import { Box } from "@mui/material";
import { HashNavLink } from "../../custom/HashNav";

const Logo = () => {
  return (
    <Box
      sx={{
        flexGrow: { xs: 0, md: 1 },
        m: "auto",
        ".logo": { display: { xs: "none", md: "block" } },
        ".logo-small": { display: { xs: "block", md: "none" } },
      }}
    >
      <HashNavLink to="/#top">
        <img
          alt="new move energy"
          className="logo"
          src="/logo.svg"
          height={55}
        />
        <img
          alt="new move energy"
          className="logo-small"
          src="/logo-small.svg"
          height={50}
        />
      </HashNavLink>
    </Box>
  );
};

export default Logo;
