import CssBaseline from "@mui/material/CssBaseline";
import "aos/dist/aos.css";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import CookieBanner from "./components/common/CookieBanner";
import Footer from "./components/common/footer/Footer";
import Header from "./components/common/header/Header";
import "./i18n";
import AboutUs from "./pages/AboutUs";
import FAQ from "./pages/FAQ";
import LegalDisclosure from "./pages/LegalDisclosure";
import Newmove from "./pages/Newmove";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SystemStatus from "./pages/SystemStatus";
import TermsAndConditions from "./pages/TermsAndConditions";
import Contact from "./components/newmove/Contact";

const isProduction = process.env.NODE_ENV === "production";

const App = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Newmove} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/system-status" component={SystemStatus} />
          <Route exact path="/legal-disclosure" component={LegalDisclosure} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/products/newmove" component={Newmove} />
          <Route exact path="/FAQ" component={FAQ} />
          <Route exact path="/contact-us" component={Contact} />
          <Redirect to="/" />
        </Switch>
        {isProduction ? <CookieBanner /> : null}
        <Footer />
      </Router>
    </>
  );
};

export default App;
