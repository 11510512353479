import { NavHashLink } from "react-router-hash-link";
import { Drawer, Box, List, ListItem, ListItemButton, ListItemText, Slide, Backdrop, Collapse } from "@mui/material";
import { Turn as Hamburger } from "hamburger-react";
import { MenuItems } from "./Header";
import ReactGA from "react-ga4";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Fragment, useState } from "react";

type MobileMenuProps = {
  menuItems: MenuItems;
  onDrawerToggle: () => void;
  mobileOpen: boolean;
};

const MobileMenu = ({ menuItems, onDrawerToggle, mobileOpen }: MobileMenuProps) => {
  const [subMenuState, setSubMenuState] = useState(menuItems.map((item) => ({ id: item.id, state: false })));

  const toggleSubMenu = (id: number) => {
    setSubMenuState((prev) => {
      const menuIndex = prev.findIndex((item) => item?.id === id);
      const newSubMenuState = [...prev];
      newSubMenuState[menuIndex].state = !prev[menuIndex].state;
      return newSubMenuState;
    });
  };

  const getSubMenuState = (id: number) => {
    return subMenuState.find((item) => item.id === id)?.state;
  };

  const clickHandler = (url: string) => {
    ReactGA.event({
      category: "Button",
      action: url,
      label: "Bookmark",
    });
    window.open(url, "_blank");
  };

  const drawer = (
    <Box>
      <Box
        sx={{
          height: "76px",
          width: "100%",
          bgcolor: "background.default",
          position: "sticky",
          zIndex: 1,
          top: 0,
        }}
      />

      <List sx={{ px: { xs: 3, sm: 4 } }}>
        {menuItems.map(({ id, title, url, subMenu }, i) => {
          if (title === "languageSwitch") return null;
          if (subMenu) {
            return (
              <Fragment key={id}>
                <Slide direction="right" in={mobileOpen} timeout={500} style={{ transitionDelay: `${i * 100}ms` }}>
                  <ListItem disablePadding>
                    <ListItemButton disableRipple divider disableGutters onClick={() => toggleSubMenu(id)}>
                      <ListItemText
                        sx={{
                          py: 1,
                          color: "text.primary",
                        }}
                        primary={title}
                      />
                      {getSubMenuState(id) ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                </Slide>
                {subMenu.length > 0 && (
                  <Collapse in={getSubMenuState(id)} timeout="auto" unmountOnExit>
                    <List sx={{ ml: 2 }}>
                      {subMenu.map((item) => (
                        <ListItemButton
                          disableRipple
                          key={item.id}
                          onClick={() => {
                            clickHandler(item.url);
                            onDrawerToggle();
                          }}
                          component={NavHashLink}
                          smooth
                          to={item.url}
                          divider
                          disableGutters
                        >
                          <ListItemText
                            sx={{
                              py: 1,
                              color: "text.primary",
                            }}
                            primary={item.title}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            );
          }
          return (
            <Slide key={id} direction="right" in={mobileOpen} timeout={500} style={{ transitionDelay: `${i * 100}ms` }}>
              <ListItem disablePadding onClick={onDrawerToggle}>
                <ListItemButton
                  disableRipple
                  onClick={() => clickHandler(url)}
                  component={NavHashLink}
                  smooth
                  to={url}
                  divider
                  disableGutters
                >
                  <ListItemText
                    sx={{
                      py: 1,
                      color: "text.primary",
                    }}
                    primary={title}
                  />
                </ListItemButton>
              </ListItem>
            </Slide>
          );
        })}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { md: "none" }, zIndex: 1300 }}>
        <Hamburger toggled={mobileOpen} toggle={onDrawerToggle} rounded size={28} />
      </Box>
      <Backdrop open={mobileOpen} sx={{ display: { xs: "block", md: "none" } }}>
        <Drawer
          variant="persistent"
          open={mobileOpen}
          onClose={onDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          transitionDuration={400}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "90%",
              maxWidth: "300px",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Backdrop>
    </>
  );
};

export default MobileMenu;
