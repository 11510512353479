import {
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { useTranslation } from "react-i18next";

const Copyrights = () => {
  const { t } = useTranslation("", { keyPrefix: "Footer.Copyrights" });
  const { t: tContact } = useTranslation("", {
    keyPrefix: "Footer.Contact",
  });
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={isMatch ? { flexDirection: "column-reverse" } : {}}>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          mt: { xs: 2, md: 0, lg: 0 },
          alignItems: { xs: "center" },
          justifyContent: { xs: "center" },
        }}
      >
        <Typography>
          © Copyright - {currentYear} | {t("identity")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack
          direction="row"
          spacing={{ xs: 1, md: 3 }}
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems="flex-start"
        >
          <Link
            component="a"
            target="_blank"
            href={`mailto:${tContact("mail")}`}
          >
            <EmailIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.linkedin")}>
            <LinkedInIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.facebook")}>
            <FacebookIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.instagram")}>
            <InstagramIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.youtube")}>
            <YouTubeIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.apple")}>
            <AppleIcon />
          </Link>
          <Link component="a" target="_blank" href={t("social.android")}>
            <AndroidIcon />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Copyrights;
