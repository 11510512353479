import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

const CookieBanner = () => {
  const { t } = useTranslation("", { keyPrefix: "CookieBanner" });

  const handleAcceptCookie = () => {
    if ("G-B78QD94RJ7") {
      ReactGA.initialize("G-B78QD94RJ7", {
        gaOptions: {
          cookieDomain: "www.newmove.energy",
        },
      });
      ReactGA.set({ anonymizeIp: true });
      ReactGA.send(window.location.pathname + window.location.search);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga", { domain: ".www.newmove.energy" });
    Cookies.remove("_ga_B78QD94RJ7", { domain: ".www.newmove.energy" });
    Cookies.remove("CookieConsent");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  return (
    <div className="Cookiebanner">
      <CookieConsent
        //debug={true}
        style={{
          background: "#FFFFFF",
          color: "#000",
          textAlign: "start",
          borderRadius: "5px",
          padding: "20px 30px",
        }}
        buttonStyle={{
          color: "#FFFFFF",
          background: "#FFC040",
          fontSize: "14px",
          borderRadius: "5px",
          padding: "7px 7px",
        }}
        declineButtonStyle={{
          background: "#CCCCCC",
          color: "#000",
          fontSize: "14px",
          borderRadius: "5px",
          padding: "7px 7px",
        }}
        buttonText={t("button")}
        declineButtonText={t("declineButton")}
        location="bottom"
        ariaAcceptLabel={t("button")}
        ariaDeclineLabel={t("declineButton")}
        overlay
        overlayStyle={{
          background: "rgba(0,0,0,0.6)",
          zIndex: "1300",
        }}
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        expires={100}
      >
        <p>
          <strong>{t("title")}</strong>
        </p>
        {t("description")} <a href="/privacy-policy">{t("text")}</a>
      </CookieConsent>
    </div>
  );
};
export default CookieBanner;
