import React from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import Xbox from "../common/Xbox";

const OurHardware: React.FC = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.OurHardware" });

  return (
    <Container
      sx={{
        alignItems: "center",
      }}
    >
      <Xbox
        title={t("title")}
        description={""}
        sx={{
          mt: { xs: 0, md: 8, lg: 6 },
          py: { xs: 2, md: 2, lg: 2 },
          textAlign: "center",
        }}
      />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={t("img.src")}
          alt={t("img.alt")}
          data-aos="zoom-in"
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "auto",
            padding: 4,
          }}
        />
      </Box>
    </Container>
  );
};

export default OurHardware;
