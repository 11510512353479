import { Grid, Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Contact = () => {
  const { t } = useTranslation("", { keyPrefix: "Footer.Contact" });
  return (
    <>
      <Grid item xs={12} id="contact-info">
        <Typography variant="subtitle1" component="h3" color="grey.50">
          {t("title")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="grey.400">{parse(t("content"))}</Typography>
        <Typography>
          {"Email: "}
          <Link underline="none" href={`mailto:${t("mail")}`}>
            {t("mail")}
          </Link>
        </Typography>
        <Typography>{`Tel: ${t("tel")}`}</Typography>
      </Grid>
    </>
  );
};

export default Contact;
