import { Container, Typography, Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Cookies } from "react-cookie-consent";
import MetaTags from "../components/common/MetaTags";

const handleDeclineCookie = () => {
  Cookies.remove("_ga", { domain: ".www.newmoveenergy.com" });
  Cookies.remove("_ga_B78QD94RJ7", { domain: ".www.newmoveenergy.com" });
  Cookies.remove("CookieConsent");
};

const PrivacyPolicy = () => {
  const { t } = useTranslation("", { keyPrefix: "PrivacyPolicy" });

  return (
    <>
      <MetaTags t={t} />
      <Container sx={{ py: 10, span: { fontSize: "2rem" } }}>
        <Button onClick={() => handleDeclineCookie()} variant="contained">
          {t("button")}
        </Button>
        <br />
        <br />
        <Typography variant="body1">
          <Trans t={t} components={{ span: <span />, br: <br /> }}>
            {t("content")}
          </Trans>
        </Typography>
      </Container>
    </>
  );
};
export default PrivacyPolicy;
