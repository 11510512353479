import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

const Rec1 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#000000",
});
const Rec2 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#dd0000",
});
const Rec3 = styled(Box)({
  width: "500px",
  height: "20px",
  backgroundColor: "#ffce00",
});

const MadeInGermany = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove.MadeInGermany" });
  return (
    <div style={{ backgroundColor: "#eeeeee" }}>
      <Container
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingTop: { xs: 6, lg: "60px" },
        }}
      >
        <Rec1 />
        <Rec2 />
        <Rec3 />
      </Container>
      <Container
        data-aos="fade-up"
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingTop: { xs: 3, lg: 2 },
          fontWeight: "bold",
          fontSize: { xs: "24px", lg: "36px" },
          letterSpacing: { xs: "2px", lg: "14px" },
        }}
      >
        {t("title")}
      </Container>
      <Container data-aos="fade-up" sx={{ display: { lg: "flex" }, paddingBottom: { xs: "20px" } }}>
        <Container
          sx={{
            paddingTop: "8px",
            padding: { xs: "15px", md: "20px", lg: "50px" },
            mx: { xs: 0, md: "200px", lg: "260px" },
            textAlign: "center",
          }}
        >
          {t("description")}
        </Container>
        {/* <Container
          sx={{
            justifyContent: { xs: "center", lg: "flex-end" },
            paddingBottom: { xs: "30px" },
            display: { xs: "flex" },
          }}
        >
          <img
            data-aos="fade-up"
            src="/static/images/Tuv_Sud.png"
            alt="tuv sud logo"
          />
        </Container> */}
      </Container>
    </div>
  );
};

export default MadeInGermany;
