import { Link, Snackbar, Alert, LinkProps } from "@mui/material";
import i18next from "i18next";
import { Fragment, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

type LanguageSwitchProps = LinkProps & { shortcode?: boolean };

const LanguageSwitch = ({
  shortcode = false,
  ...rest
}: LanguageSwitchProps) => {
  const { t } = useTranslation("", { keyPrefix: "LanguageSwitch" });

  const [currentLanguage, setLanguage] = useState([
    i18next.resolvedLanguage,
    "",
  ]);
  const [open, setOpen] = useState(false);

  const handleChangeLanguage = (language: string[]) => {
    i18next.changeLanguage(language[0]);
    setLanguage(language);
    setOpen(true);

    ReactGA.event({
      category: 'Button',
      action: 'Language Switch',
      label: 'Bookmark'
    });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const result = !currentLanguage[0]?.includes("en")
    ? ["en", "English"]
    : ["de", "German"];

  const getLangName = () => {
    return shortcode ? result[0].toUpperCase() : result[1];
  };

  return (
    <Fragment>
      {
        <Link {...rest} onClick={() => handleChangeLanguage(result)}>
          {getLangName()}
        </Link>
      }
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {t("alert")} {currentLanguage[1]}!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default LanguageSwitch;
