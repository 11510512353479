import { Box, Container, Grid, Typography, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import parse from "html-react-parser";
import { HashNavButton } from "../custom/HashNav";
import ReactGA from "react-ga4";
import Notify from "../common/Notify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";

type XboxProps = {
  title: string;
  isFeature?: boolean;
  isYoutube?: boolean;
  description: string;
  container?: boolean;
  type?: "center" | "split";
  width?: number[];
  bg?: string;
  imgSrc?: string;
  imgSrcOp?: string;
  imgAlt?: string;
  leftBgcolor?: string;
  rightBgcolor?: string;
  sx?: SxProps<Theme>;
  fillImg?: boolean;
  reverse?: boolean;
  headerImgSrc?: string;
  headerImgAlt?: string;
  btnText?: string;
  btnLink?: string;
  productId?: string;
  alignText?: boolean;
  className?: string;
  note?: string;
  youtube?: string;
};

const Xbox = ({
  title,
  description,
  container = true,
  type = "center",
  bg = "transparent",
  imgSrc = "",
  imgSrcOp = "",
  imgAlt = "",
  leftBgcolor = "",
  rightBgcolor = "",
  sx,
  fillImg = false,
  reverse = false,
  isFeature = false,
  isYoutube = false,
  width = [6, 6],
  headerImgSrc = "",
  headerImgAlt = "",
  btnText,
  btnLink,
  productId = "",
  alignText = false,
  className = "",
  note = "",
  youtube = "",
}: XboxProps) => {
  const clickHandler = (url: string | undefined) => {
    if (url)
      ReactGA.event({
        category: "Button",
        action: url,
        label: "Bookmark",
      });
  };

  const getTypeContent = () => {
    switch (type) {
      case "split":
        return (
          <>
            <Grid
              container
              className={className}
              overflow="hidden"
              sx={
                reverse
                  ? {
                      flexDirection: {
                        // xs: "column",
                        xs: `${isYoutube ? "column-reverse" : "column"}`,
                        lg: "row-reverse",
                      },
                    }
                  : { flexDirection: { xs: `${isYoutube ? "column-reverse" : "column"}`, lg: "row" } }
              }
            >
              <Grid
                container
                item
                xs={12}
                lg={width[0]}
                bgcolor={{ xs: leftBgcolor, lg: "unset" }}
                pt={{ xs: 3, lg: 10 }}
                pb={{ xs: 7, lg: 10 }}
                pl={{ xs: 2, sm: 3, lg: container ? 0 : 10 }}
                pr={{ xs: 2, sm: 3, lg: 0 }}
                alignContent={!container && fillImg ? "center" : {}}
                alignSelf={alignText ? "flex-start" : {}}
                sx={
                  container
                    ? reverse
                      ? { pl: { xs: 2, sm: 3, lg: 10 } }
                      : { pr: { xs: 2, sm: 3, lg: 10 } }
                    : { px: { xs: 2, sm: 3, lg: 10 } }
                }
                className="xbox-left"
              >
                {headerImgSrc && (
                  <Grid
                    item
                    xs={12}
                    className="xbox-header-img"
                    sx={{
                      img: {
                        width: "60%",
                      },
                      display: { xs: "none", lg: "block" },
                    }}
                  >
                    <img alt={headerImgAlt} src={headerImgSrc} data-aos="zoom-in" />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "flex-start",
                  }}
                >
                  {isFeature && <img src="/energy-logo.svg" alt="energy logo" data-aos="zoom-in" />}
                  <Typography
                    variant="h1"
                    component="h2"
                    sx={{
                      fontSize: {
                        xs: `${isFeature ? "36px" : "36px"}`,
                        md: `${isFeature ? "32px" : "3rem"}`,
                        lg: `${isFeature ? "40px" : "3rem"}`,
                      },
                      pr: {
                        xs: `${isFeature && "20px"}`,
                        md: `${isFeature && "10px"}`,
                      },
                      span: {
                        color: "primary.main",
                      },
                    }}
                    data-aos="fade-up"
                  >
                    {parse(title)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "justify",
                    hyphens: "auto",
                    paddingRight: {
                      xs: `${isFeature && "30px"}`,
                      md: `${isFeature && "120px"}`,
                      lg: `${isFeature && "80px"}`,
                    },
                  }}
                >
                  {isFeature ? (
                    <ul>
                      <Typography
                        // component={"span"}
                        variant="body1"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        {parse(description)}
                      </Typography>
                    </ul>
                  ) : (
                    <Typography variant="body1" data-aos="fade-up" data-aos-delay="200">
                      {parse(description)}
                    </Typography>
                  )}
                  {(productId || btnText) && (
                    <Box mt={4} data-aos="fade-up" data-aos-delay="350">
                      {productId && (
                        <Box py={1} sx={{ display: "inline-block", mr: 4 }}>
                          <Notify productId={productId} />
                        </Box>
                      )}
                      {btnText && (
                        <HashNavButton
                          onClick={() => clickHandler(btnLink)}
                          variant={productId ? "outlined" : "contained"}
                          color={productId ? "info" : "primary"}
                          to={btnLink}
                        >
                          {btnText}
                        </HashNavButton>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                bgcolor={{ xs: rightBgcolor, lg: "unset" }}
                lg={width[1]}
                textAlign={{ xs: "center", lg: "inherit" }}
                style={{ width: "100%" }}
                sx={
                  fillImg
                    ? container
                      ? {}
                      : {
                          img: {
                            height: { xs: "auto", lg: "100%" },
                            objectFit: "cover",
                          },
                        }
                    : reverse
                    ? {
                        pr: { xs: 2, sm: 3, lg: 7 },
                        py: { xs: 2, lg: 10 },
                      }
                    : {
                        pl: { xs: 2, sm: 3, lg: 7 },
                        py: { xs: 2, lg: 10 },
                      }
                }
                className="xbox-img"
              >
                {imgSrc && <LazyLoadImage data-aos="zoom-in" alt={imgAlt} src={imgSrc} />}
                {youtube && (
                  <div
                    style={{
                      position: "relative",
                      paddingTop: "56.25%",
                    }}
                  >
                    <ReactPlayer
                      style={{ position: "absolute", top: 0, left: 0 }}
                      url={youtube}
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            {note && (
              <Grid container item xs={12} pb={6} px={{ xs: 2, sm: 3, lg: 0 }}>
                <Typography variant="body2" textAlign="left">
                  {parse(note)}
                </Typography>
              </Grid>
            )}
          </>
        );
      case "center":
        return (
          <Grid
            sx={sx}
            container
            px={{ xs: 2, sm: 3, lg: 10 }}
            py={{ xs: 5, lg: 10 }}
            textAlign={{ xs: "left", lg: "center" }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h1"
                component="h2"
                sx={{
                  fontSize: { xs: "2.5rem", lg: "3rem" },
                  span: {
                    color: "primary.main",
                  },
                }}
                data-aos="fade-up"
              >
                {parse(title)}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Typography data-aos="fade-up" data-aos-delay="200">
                {parse(description)}
              </Typography>
            </Grid>
            {productId && (
              <Grid item xs={12} mt={4} data-aos="fade-up" data-aos-delay="400">
                <Notify productId={productId} />
              </Grid>
            )}
          </Grid>
        );
    }
  };

  const getContent = () => {
    if (container) return <Container sx={{ px: { xs: 0, lg: 4 } }}>{getTypeContent()}</Container>;
    return getTypeContent();
  };

  const getBgcolor = () => {
    if (leftBgcolor || rightBgcolor) {
      return `linear-gradient( to right,  ${leftBgcolor} 0%,  ${leftBgcolor} 50%, ${rightBgcolor} 50%, ${rightBgcolor} 100%)`;
    }
    return bg;
  };

  return <Box sx={{ background: getBgcolor(), ...sx }}>{getContent()}</Box>;
};

export default Xbox;
