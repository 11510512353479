import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Xbox from "../common/Xbox";
import Grid from "@mui/material/Grid";

const PowrPeak = () => {
  const { t } = useTranslation("", { keyPrefix: "Newmove" });
  const [isFirstImageVisible, setIsFirstImageVisible] = useState(true);

  const imgSrc = t("AbsorbPower.img.src");
  const imgSrcOp = t("AbsorbPower.img.src_op");

  useEffect(() => {
    const timer = setInterval(() => {
      setIsFirstImageVisible((prevState) => !prevState);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "auto" }}>
      <Grid item xs={12} md={6}>
        <Xbox
          type="split"
          title={t("AbsorbPower.title")}
          description={t("AbsorbPower.description")}
          fillImg
          imgAlt={t("AbsorbPower.img.alt")}
          imgSrc={isFirstImageVisible ? imgSrc : imgSrcOp}
          sx={{
            color: "#474747",
            pt: { xs: 2, md: 10, lg: 0 },
            pb: { xs: 0, md: 6, lg: 0 },
            ".MuiGrid-grid-xs-12": {
              pt: { xs: 2, md: 0, lg: 0 },
              px: { xs: 2, md: 0, lg: 0 },
            },
            ".MuiGrid-root": {
              pb: { xs: "10px", md: "40px" },
              alignContent: "center",
            },
            ".MuiGrid-container": {
              mt: { md: 0, lg: 0 },
              mr: { md: 6, lg: 0 },
            },
            ".MuiTypography-root": {
              pr: { xs: 0, md: 2, lg: 2 },
              mt: { xs: 2, md: 0 },
            },
            ".MuiTypography-body1": {
              textAlign: "justify",
              hyphens: "auto",
              pr: { md: 4, lg: 12 },
              mt: { md: 2, lg: 0 },
            },
          }}
        />
      </Grid>
    </div>
  );
};

export default PowrPeak;
