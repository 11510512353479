import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MetaTags from "../components/common/MetaTags";
import parse from "html-react-parser";

const TermsAndConditions = () => {
  const { t } = useTranslation("", { keyPrefix: "TermsAndConditions" });

  return (
    <>
      <MetaTags t={t} />
      <Container sx={{ py: 2, span: { fontSize: "2rem" } }}>
        <br />
        <br />
        <Typography variant="body1">{parse(t("content"))}</Typography>
      </Container>
    </>
  );
};
export default TermsAndConditions;
