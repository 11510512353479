import { useTranslation } from "react-i18next";
import MetaTags from "../components/common/MetaTags";
import Xbox from "../components/common/Xbox";

const AboutUs = () => {
  const { t } = useTranslation("", { keyPrefix: "AboutUs" });

  return (
    <>
      <MetaTags t={t} />
      <Xbox
        type="split"
        title={t("title")}
        description={t("description")}
        imgAlt={t("img.alt")}
        imgSrc={t("img.src")}
        alignText
      />
    </>
  );
};

export default AboutUs;
