import { Box, Container, Grid, Link } from "@mui/material";
import { MenuItems } from "./Header";
import { HashNavLink, HashNavButton } from "../../custom/HashNav";
import LanguageSwitch from "../LanguageSwitch";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";

type MenuProps = {
  menuItems: MenuItems;
};

const Menu: React.FC<MenuProps> = ({ menuItems }) => {
  const history = useHistory();

  const clickHandler = (url: string) => {
    if (url === "store") {
      window.open("https://klc.solar/collections/newmove", "_blank");
    } else if (url === "faq") {
      window.open("faq", "_blank");
    } else {
      ReactGA.event({
        category: "Button",
        action: url,
        label: "Bookmark",
      });
    }
  };

  const handleClick = (url: string) => {
    if (url !== "store") {
      clickHandler(url);
      history.push(url);
    }
  };

  const menuList = menuItems.map(({ id, title, url, btn, subMenu }, i, arr) => {
    if (title === "languageSwitch")
      return (
        <Box
          key={id}
          sx={{
            display: "inline-block",
          }}
        >
          <LanguageSwitch sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }} underline="none" />
        </Box>
      );
    if (title === "Store") {
      return (
        <Box sx={{ display: "inline-block" }} key={id}>
          <Link
            onClick={() => clickHandler(url)}
            href={url}
            target="_blank"
            underline="none"
            sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
          >
            {title}
          </Link>
        </Box>
      );
    }
    if (title === "FAQs") {
      return (
        <Box sx={{ display: "inline-block" }} key={id}>
          <Link
            onClick={() => clickHandler("faq")}
            sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
            underline="none"
            target="_blank"
          >
            {title}
          </Link>
        </Box>
      );
    }
    if (title === "Documentation" || title === "Dokumentation") {
      return (
        <Box sx={{ display: "inline-block" }} key={id}>
          <Link
            href="https://docs.newmove.energy"
            target="_blank"
            underline="none"
            sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
          >
            {title}
          </Link>
        </Box>
      );
    }

    if (!btn) {
      if (subMenu && subMenu.length > 0)
        return (
          <Box
            key={id}
            sx={{
              display: "inline-block",
              ":hover .sub-items": {
                maxHeight: 500,
                py: 4,
              },
            }}
          >
            <HashNavLink to={url} underline="none" sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}>
              {title}
            </HashNavLink>
            <Box
              className="sub-items"
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                width: "100%",
                py: 0,
                maxHeight: 0,
                overflow: "hidden",
                bgcolor: "background.default",
                boxShadow: "0px 4px 15px 0px #0000000D",
              }}
            >
              <Container>
                <Grid container>
                  {subMenu.map((item) => (
                    <Grid
                      container
                      item
                      xs={2}
                      key={item.id}
                      onClick={() => handleClick(item.url)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Grid item xs={12}>
                        <img alt={item.title} src={item.imgSrc} style={{ height: "100%", width: "auto" }} />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        {item.title}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </Box>
          </Box>
        );
      return (
        <Box sx={{ display: "inline-block" }} key={id}>
          <HashNavLink
            onClick={() => clickHandler(url)}
            to={url}
            underline="none"
            sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
          >
            {title}
          </HashNavLink>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "inline-block" }} key={id}>
        <HashNavButton
          onClick={() => clickHandler(url)}
          variant="contained"
          to={url}
          sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
        >
          {title}
        </HashNavButton>
      </Box>
    );
  });

  return <Box sx={{ display: { xs: "none", md: "block" } }}>{menuList}</Box>;
};

export default Menu;
